import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Container, PageContent, Content, ScrollContent } from './styles';

import MobileMenu from '../../components/MobileMenu';
import Header from '../../components/Header';
import IndicationBar from '../../components/Indication/Bar';
import OldHeader from '../../components/OldHeader';
import { isAppV2, isOldApp } from '../../services/versionManager';

const DefaultLayout: React.FC & { hideIndicationBar?: boolean } = props => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [newHeaderMenu, setNewHeaderMenu] = useState(null);

  useEffect(() => {
    setNewHeaderMenu(isAppV2() || !isOldApp());
  }, []);

  return (
    <>
      <Head>
        <title>Cointimes</title>
      </Head>
      <Container>
        <PageContent>
          {newHeaderMenu !== null ? (newHeaderMenu == true ? <Header/> : <OldHeader/>) : <></>}
          <ScrollContent>
            {width >= 770 && !window.location.pathname.startsWith('/airdrop/kanna') && <IndicationBar />}
            <Content>{props.children}</Content>
          </ScrollContent>
        </PageContent>
      </Container>
      <MobileMenu />
    </>
  );
};

export default DefaultLayout;
