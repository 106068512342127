import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { NewContainer } from './styles';
import Button from './Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faDiceFour, faBookOpenReader, faChartLine, faHome, faCompass, faWallet } from '@fortawesome/free-solid-svg-icons';
import { isAppV2 } from '../../services/versionManager';

const NewMobileMenu: React.FC = () => {
  const { pathname: activePage, push } = useRouter();
  const [showMobileMenu, setShowMobileMenu] = useState(true);

  let lastScrollPos = 0;

  const handleChangePage = (page: string) => {
    if(page.startsWith('/open-in-app')) {
      window.location.href = page;
    } else {
      push(page);
    }

    setShowMobileMenu(!page.startsWith('/discover'));
  };

  const menuItems = [
    {
      'page': '/home',
      'icon': faHome
    },
    {
      'page': '/read-to-earn',
      'icon': faBookOpenReader
    },
    {
      'page': '/discover',
      'icon': faCompass
    },
    {
      'page': '/open-in-app/reward/welcome',
      'icon': faDiceFour
    },
    {
      'page': '/market',
      'icon': faChartLine
    },
    {
      'page': '/stores',
      'icon': faCartShopping
    },
    {
      'page': '/cashback',
      'icon': faWallet
    },
  ]

  useEffect(() => {
    setShowMobileMenu(!activePage.startsWith('/discover'));
  }, [activePage]);

  useEffect(() => {
    if (window.location.pathname.startsWith('/discover')) {
      setShowMobileMenu(false);
      return;
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  function onScroll() {
    if (window.location.pathname.startsWith('/discover')) {
      return;
    }

    let scrollY = Math.max(0, window.scrollY);

    setShowMobileMenu(lastScrollPos > scrollY);
    lastScrollPos = scrollY;
  }

  return (
    <NewContainer className={showMobileMenu === true ? 'show' : 'hide'}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 60,
        backgroundColor: activePage.startsWith('/airdrop') ? '#333' : 'black',
        borderRadius: 30
      }}>
        {menuItems.filter(
          (value) => (isAppV2() || !value.page.startsWith('/open-in-app'))
        ).map((value, idx) => {
          return <Button
            key={idx}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={() => handleChangePage(value['page'])}
            active={activePage === value['page'] || window.location.pathname === value['page']}
          >
            <FontAwesomeIcon fontSize='calc(15px + 0.390625vw)' icon={value['icon']} />
          </Button>
        })}
      </div>
    </NewContainer>
  );
};

export default NewMobileMenu;
